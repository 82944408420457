html {
  scroll-behavior: smooth;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.DesktopOnly {
  display: inline-block;
}

.MobileOnly {
  display: none;
}

@media only screen and (max-width: 992px) {
  .DesktopOnly {
    display: none;
  }
  .MobileOnly {
    display: inline-block;
  }
}

@media only screen and (max-width: 992px) {
.App-Desktop-Menu {
  display: none !important;
}
}

@media only screen and (min-width: 992px) {
  .App-Mobile-Menu {
    display: none !important;
  }
}

.MainContainerCenter {
  top: 33%;
}

@media only screen and (max-width: 600px) {
  .MainContainerCenter {
    top: 20%;
  }
}

@media only screen and (max-width: 468px) {
  .MainContainerCenter {
    top: 10%;
  }
}

.ContactContainer {
  min-height: 100vh;
  padding: 10vh;
}

@media only screen and (max-width: 899px) {
  .ContactContainer {
    min-height: 50vh;
  }
}


@media only screen and (max-width: 489px) {
  .Footer-logo {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .Footer-logo {
    height: 5rem !important;
  }
}

.MuiAlert-standardSuccess.MuiAlert-standardSuccess {
  background-color: rgb(237, 247, 237);
  color: rgb(30, 70, 32);
}

.MuiAlert-standardError.MuiAlert-standardError {
  background-color: rgb(253, 237, 237);
  color: rgb(95, 33, 32);
}