/* This file is a very bad hack but it works so let's keep it. */

.order-form .MuiInputBase-root.MuiInputBase-root {
    color: #bbbbbb;
}

.order-form .MuiInputBase-root.MuiInputBase-root svg {
    color: rgb(255, 255, 255, 0.70);
}

.order-form .MuiInputBase-root.MuiInputBase-root:hover svg {
    color: #ffffff;
}


.order-form .MuiInputAdornment-root.MuiInputAdornment-root .MuiButtonBase-root.MuiButtonBase-root svg {
    color: rgb(255, 255, 255, 0.70);
}

.order-form .MuiInputAdornment-root.MuiInputAdornment-root .MuiButtonBase-root.MuiButtonBase-root:hover svg {
    color: #ffffff;
}

.order-form .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
    border-color: #888888;
}

.order-form .MuiInputBase-root.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
    border-color: #cccccc;
}

.order-form .MuiInputBase-root.Mui-error.MuiInputBase-root.Mui-error:hover .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
    border-color: #f44336;
}

.order-form .MuiInputBase-root.Mui-focused.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
    border-color: rgb(49, 76, 112);
}

.order-form .MuiInputBase-root.Mui-focused.Mui-error.MuiInputBase-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
    border-color: #f44336;
}

.order-form .MuiInputBase-root:hover.MuiInputBase-root:hover {
    color: #dddddd;
}

.order-form .MuiFormLabel-root.MuiFormLabel-root {
    color: rgb(255, 255, 255, 0.78);
}

.order-form .MuiFormLabel-root.Mui-focused.MuiFormLabel-root.Mui-focused {
    color: rgb(72, 107, 172);
}

.order-form .MuiFormLabel-root.Mui-error.MuiFormLabel-root.Mui-error {
    color: #f05247;
}

.order-form .MuiFormLabel-root.Mui-focused.Mui-error.MuiFormLabel-root.Mui-focused.Mui-error {
    color: #f7665c;
}

.order-form .MuiInputBase-input {
    color: #dddddd;
}

@keyframes order-button-gradient {
    from {
        transform: scale(1.0);
    }
    to {
        transform: scale(1.1);
    }
}

.order-button.MuiButton-root {
    background: rgb(2,0,36);
    background: linear-gradient(135deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 50%);
}

.order-button.MuiButton-root:hover {
    animation: order-button-gradient 500ms ease-in-out;
    animation-fill-mode: forwards;
}